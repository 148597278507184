<template>
  <div>
    <CSBreadcrumb />
    <DroneMap
      style="height: 884px"
      show-control
      :isSubmitting="isSubmitting"
      @changeField="changeItem"
      @onSave="saveMission"
      :flight="flightHeight"
      :back="courseReversalHeight"
      :default-way-points="wayPoints"
      :default-name="name"
    />
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import { addFlightMissionUrl, editFlightMissionUrl } from "@/requestUrl";
import DroneMap from "@/views/Drones/DroneMap";

export default {
  name: "AddFlightMission",
  components: { DroneMap, CSBreadcrumb },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    defaultName: String,
    flight: Number,
    back: Number,
    defaultWayPoints: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: "",
      isSubmitting: false,
      flightHeight: "",
      courseReversalHeight: "",
      wayPoints: [],
    };
  },
  mounted() {
    this.name = this.defaultName ?? "";
    this.flightHeight = this.flight || "";
    this.courseReversalHeight = this.back || "";
    this.wayPoints = this.defaultWayPoints;
  },
  methods: {
    changeItem({ type, val }) {
      this[type] = val;
    },
    async saveMission() {
      const { name, flightHeight, courseReversalHeight, wayPoints } = this;
      if (!name) {
        this.$vc.message("请输入任务名称");
        return;
      }
      if (wayPoints.length === 0) {
        this.$vc.message("请选择飞行点");
        return;
      }
      if (wayPoints.length < 2) {
        this.$vc.message("最少2个航点，否则无法保存");
        return;
      }

      if (!flightHeight) {
        this.$vc.message("请设置飞行高度");
        return;
      }
      if (!courseReversalHeight) {
        this.$vc.message("请设置返程高度");
        return;
      }
      if (
        flightHeight < 20 ||
        flightHeight > 500 ||
        courseReversalHeight < 20 ||
        courseReversalHeight > 500
      ) {
        this.$vc.message("高度限20~500");
        return;
      }
      const params = {
        name,
        flightHeight,
        courseReversalHeight,
        regionCode: this.$vc.getCurrentRegion().code,
        wayPoints: JSON.stringify(wayPoints.map((item) => item.toString())),
      };
      if (this.id) {
        params.id = this.id;
        delete params.regionCode;
      }
      this.isSubmitting = true;
      this.$fly
        .post(this.id ? editFlightMissionUrl : addFlightMissionUrl, params)
        .then((res) => {
          if (res?.code === 0) {
            this.$vc.toast(`${this.id ? "修改" : "添加"}成功`);
            this.$router.push({ name: "flightMission" });
          }
        })
        .finally(() => (this.isSubmitting = false));
    },
  },
};
</script>

<style lang="stylus" scoped>
.mission-map {
  height: 844px;
  width: 100%;
  position: relative;

  .search-address {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;

    input {
      width: 220px;
      height: 30px;
      border-radius: 3px;
      border: 1px solid #999;
      padding: 5px 10px;
      margin-right: 20px;
      vertical-align: middle;
    }

    .btn {
      height: 30px;
      padding: 0 12px;
      vertical-align: middle;
    }

    .search-panel {
      position: absolute;
      left: 0;
      top: 34px;
      background: #fff;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #999;

      p {
        cursor: pointer;
        margin-bottom: 0;

        &:hover {
          background: #ddd;
        }

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .map-control {
    position: absolute;
    right: 20px;
    bottom: 30px;
    z-inde: 1;

    & > div {
      background: #00B694;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
      font-size: 22px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .mission-info {
    position: absolute;
    right: 0;
    top: 0;
    width: 684px;
    height: 80px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
    padding: 10px 20px;
    font-size: 14px;

    .tips {
      color: #999;
    }

    .base-info {
      margin-bottom: 10px;

      & > div {
        display: inline-block;

        input {
          padding: 0 10px;
          height: 30px;
          border: 1px solid #999999;
          opacity: 1;
          border-radius: 3px;
        }

        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }
  }

  .btn-save {
    position: absolute;
    bottom: 30px;
    left: 50%;
    height: 40px;
    padding: 0 12px;
    width: 200px;
    color: #fff;
    transform: translateX(-50%);
    font-size: 24px;
  }

  #missionMap {
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
  }
}
</style>
